import "./App.css";
import "./Home.css";

import Navbar from "./components/Navbar/Navbar.js";

import charms from "./assets/charms.jpeg"
import place from "./assets/place.jpeg";
import popup from "./assets/popup.jpeg";
import eventTable from "./assets/event-table.jpeg";
import letUs from "./assets/let-us.jpeg"
import shell from "./assets/shells.jpeg"
import popupLogo from "./assets/drakeside-logo.webp"
import { useEffect } from "react";

export default function Home() {
  useEffect(() => {
    document.title = "Coastal Charm";
  }, []);

  return (
    <div id="home">
      <Navbar coloredBg={true} />
      <div className="main-body">
        <div id="top-row">
          <div>
            <h1>custom charm jewelry</h1>
            <div className="design-step">
              <img src={charms} alt="12 different charms"/>
              <span><span className="design-step-num">1</span>choose your chain and charms<span></span></span>
            </div>
            <div className="design-step">
              <img src={place} alt="a bracelet with the charms positioned below it" />
              <span><span className="design-step-num">2</span>place your charms on your chain<span></span></span>
            </div>
            <div className="design-step">
              <img src={letUs} alt="a necklace between two pairs of pliers" />
              <span><span className="design-step-num">3</span>let us do the rest!<span></span></span>
            </div>
            {/*<Link className="charm-button">design yours</Link>*/}
          </div>
          <img src={popup} alt="a Coastal Charm popup" />
        </div>
        <i></i>
        <div className="showcase-section">
          <div>
            <h2>visit an event</h2>
            <p>Watch your jewelry be made in person. </p>
            <div className="popup-info">
              <span><i>our next popup</i></span>
              <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                <span id="month">NOV</span>
                <span id="day">21</span>
              </div>
              <img id="popup-logo" src={popupLogo} alt="drakeside logo"/>
            </div>
            <p>Follow our Instagram to stay up to date on our popup charm bars</p>
            <a href="http://instagram.com/coastalcharm_nh">
              <span id="insta-info">
                <span>@coastalcharm_nh</span>
              </span>
            </a>
        </div>
        <img src={eventTable} alt="an event table" />
        </div>
        <div className="showcase-section">
          <div>
            <h2>hand-picked quality charms and chains</h2>
            <p>We only use allergy friendly, tarnish-resistant stainless steel that will last. </p>
          </div>
          <img src={shell} alt="three charms between two seashells" />
        </div>
      </div>
    </div>
  );
}
import { useState, useEffect, useRef } from "react";
import { DndContext, DragOverlay } from "@dnd-kit/core";

import Drawer from "./Drawer/Drawer.js"
import DrawerDropAreas from "./DrawerDropAreas.js";
import CharmIcon from "./CharmIcon.js";
import DesignArea from "./DesignArea.js";
import styles from "./Order.module.css"
import OptionsBar from "./OptionsBar.js";
import Checkout from "./Checkout/Checkout.js"

let charm_unique_id = 0;

export default function Order() {
  const [drawerExpanded, setDrawerExpanded] = useState(true);
  const [drawerScroll, setDrawerScroll] = useState(0);
  const [checkoutOpen, setCheckoutOpen] = useState(false);

  const [charms, setCharms] = useState([]);
  const [chains, setChains] = useState([]);
  const [activeChainIndex, setActiveChainIndex] = useState(null);
  const [lengthIndex, setLengthIndex] = useState(0);

  const [activeCharms, setActiveCharms] = useState([]);
  const [draggingCharm, setDraggingCharm] = useState(null);

  const [cart, setCart] = useState([]);

  const [totalCost, setTotalCost] = useState(0);

  const designAreaRef = useRef(0);

  // fetch charm and chain data from server
  useEffect(() => {
    fetch("/api/charms", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "get",
    }).then(res => res.json())
      .then(res => setCharms(res));

    fetch("/api/chains", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "get",
    }).then(res => res.json())
      .then(res => setChains(res))
      .then(() => setActiveChainIndex(0))
  }, []);

  // compute total cost, update when cart or chain are modified
  useEffect(() => {
    setTotalCost(cart.reduce((totalCostAcc, piece) => {
      const chainCost = piece.chain.price[lengthIndex];
      return totalCostAcc + chainCost + piece.charms.reduce((costAcc, charm) => {
        return costAcc + charm.price;
      }, 0)
    }, 0));
  }, [cart, lengthIndex]);

  function openCheckout() {
    setCheckoutOpen(true);
  }

  function addToCart() {
    // if there are no charms on the necklace, do not add piece to cart
    if (activeCharms.length === 0) {
      return;
    }

    setCart(cart.concat({
      chain: chains[activeChainIndex],
      charms: activeCharms,
      lengthIndex: lengthIndex
    }));
    setActiveCharms([]);
  }

  const removeFromCart = index => setCart(cart.filter((_, i) => i !== index));

  function stripUniqueId(name) {
    return name.split("#")[0];
  }

  function moveCharm(movedCharmId, delta) {
    setActiveCharms(activeCharms.map(charm => {
      if (movedCharmId === charm.name) {
        return {
          ...charm,
          x: charm.x + delta.x,
          y: charm.y + delta.y,
        }
      } else {
        return charm;
      }
    }));
  }

  const deleteCharm = id =>  setActiveCharms(activeCharms.filter(charm => charm.name !== id));

  function selectPrevChain() {
    if (activeChainIndex > 0) {
      setActiveChainIndex(activeChainIndex - 1);
      setLengthIndex(0);
    }
  }

  function selectNextChain() {
    if (activeChainIndex < chains.length - 1) {
      setActiveChainIndex(activeChainIndex + 1);
      setLengthIndex(0);
    }
  }

  return (
    <div className={styles.order}>
      <DndContext
        onDragStart={handleDragStart}
        onDragMove={handleDragMove}
        onDragEnd={handleDragEnd}
        autoScroll={false}
      >
        <Drawer
          expanded={drawerExpanded}
          setScroll={setDrawerScroll}
          charms={charms}
        />
        <DesignArea
          divRef={designAreaRef}
          activeCharms={activeCharms}
          moveCharm={moveCharm}
          deleteCharm={deleteCharm}
          setDrawerExpanded={setDrawerExpanded}
          activeChainUrl={activeChainIndex !== null ? chains[activeChainIndex].imgUrl : ""}
        />
        <DrawerDropAreas/>
        <DragOverlay dropAnimation={null}>
          {draggingCharm ? <CharmIcon charm={draggingCharm}/> : null}
        </DragOverlay>
      </DndContext>
      <OptionsBar
        addToCart={addToCart}
        lengths={activeChainIndex !== null ? chains[activeChainIndex].lengths : []}
        selectPrevChain={selectPrevChain}
        selectNextChain={selectNextChain}
        openCheckout={openCheckout}
        cartSize={cart.length}
        setLengthIndex={setLengthIndex}
        clearCharms={() => setActiveCharms([])}
      />
      <Checkout
        cart={cart}
        totalCost={totalCost}
        open={checkoutOpen}
        removeFromCart={removeFromCart}
        closeCheckout={() => setCheckoutOpen(false)}
        clientWidth={designAreaRef.current.clientWidth}
        clientHeight={designAreaRef.current.clientHeight}
        />
    </div>
  );

  function handleDragStart({active}) {
    if (active.id !== "charm-drawer") {
      const charmData = charms.filter(c => c.name === stripUniqueId(active.id))[0];
      setDraggingCharm(charmData);
    } else {
      setDraggingCharm(null);
    }
  }

  function handleDragMove({active, delta}) {
    if (active.id !== "charm-drawer") {
      if (delta.x + delta.y > 75) {
        setDrawerExpanded(false);
      }
    }
  }

  function handleDragEnd({active, activatorEvent, delta, over}) {
    // if component is dropped outside a Droppable component, do nothing
    if (!over) {
      return;
    }

    if (active.id === "charm-drawer") {
      setDrawerExpanded(over.id === "expand-drawer-area");
    } else {
      if (over.id === "expand-drawer-area") {
        const charmData = charms.filter(c => c.name === active.id)[0];
        setCharms(charms.map(c => {
          if (c.name === active.id) {
            return {
              ...c,
              stock: c.stock - 1
            }
          }
          return c;
        }));
        setActiveCharms(
          activeCharms.concat({
            ...charmData,
            name: active.id + "#" + charm_unique_id++,
            x: delta.x + activatorEvent.clientX - activatorEvent.layerX,
            y: delta.y + activatorEvent.clientY - activatorEvent.layerY + drawerScroll,
            selected: true,
          })
        );
      }
    }
  }
}

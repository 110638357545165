import { useState } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import styles from "./Checkout.module.css";

export default function PaymentForm({ cart, confirmPayment, setPaymentSuccessful, clientWidth, clientHeight }) {
  const [submitting, setSubmitting] = useState(false);
  const stripe = useStripe();
  const elements = useElements();

  async function submitOrder(event) {
    event.preventDefault();

    // validate form data
    const {error: submitError} = await elements.submit();
    if (submitError) {
      return;
    }

    setSubmitting(true);

    // create ConfirmationToken
    const {error, confirmationToken} = await stripe.createConfirmationToken({
      elements
    });

    fetch("/api/create-confirm-intent", {
      method: "POST",
      headers: {"Content-Type": "application/json"},
      body: JSON.stringify({
        confirmationTokenId: confirmationToken.id,
        items: cart,
        clientWidth: clientWidth,
        clientHeight: clientHeight,
      })
    })
      .then(res => res.json())
      .then(data => {
        if (data.error) {
          setPaymentSuccessful(false);
        } else {
          setPaymentSuccessful(true);
          confirmPayment();
        }
      })
      .catch(e => {
        setPaymentSuccessful(false);
        confirmPayment();
      });
  }

  return (
    <form onSubmit={submitOrder}>
      <PaymentElement/>
      <button className={styles.payButton}>
       {
          submitting ? "processing . . ." : "pay now"
        }
      </button>
    </form>
  );
}

import styles from "./Checkout/Checkout.module.css"
import Piece from "./Piece/Piece.js"

export default function Bag({cart, totalCost, removeFromCart}) {
  const shippingCost = 10;

  return(
    <div style={{width: "100%"}}>
      <div className={styles.bag}>
        <div className={styles.bagItems}>
          {
            cart.map((piece, i) =>
              <Piece piece={piece} removePiece={() => removeFromCart(i)}/>
            )
          }
          <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginTop: "16px"}}>
            <span>Subtotal</span>
            <span>${totalCost}</span>
          </div>
          <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginTop: "16px"}}>
            <span>Shipping</span>
            <span>$10</span>
          </div>
          <div style={{width: "100%", display: "flex", justifyContent: "space-between", marginTop: "16px"}}>
            <span>Total</span>
            <span>${totalCost + shippingCost}</span>
          </div>
        </div>
      </div>
    </div>
  );
}